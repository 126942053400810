console.log("Tender Heights EPK");

import { RotoPlayer } from "./player";
import 'blissfuljs';

let player;
const initializePlayer = function() {
    // 
    player = new RotoPlayer($(".th-player"),{
        customPlayMark : ()=>{return;},
        customPauseMark : ()=>{return;},
        noFlex : true,
        noInfo : false,
        buttonRadius : .15,
        labelSize : .05,
        playhead : 0,
        playheadColor : "#edeef8",
        fontFamily : "Josefin Sans, sans-serif",
        playheadLabelAngle : 30,
        runnerRadius : .245,
        runnerColor : "#dbdef1",
        stateColor : "#edeef8",
        trackRadius : .265,
        trackColor : "#edeef8",
        trackLength : 30, // track length in seconds
        trackWidth : .018,
        trackVariation : 1 // factor to bulge thickest part of track        
    }).initialise();

};
initializePlayer();

$.ready(async function() {

    // set up play functionalitys
    const playButtons = $$("[data-play]");
    const handleplaychange = (player)=>{
        console.log('play change!',player.src,player);
        playButtons.forEach((btn)=>{
            const src = btn.dataset.play;
            if ((src == player.src) && (player.running)) btn.classList.add("playing");
            else btn.classList.remove("playing");
        });
    };
    playButtons.forEach((btn)=>{
        const src = btn.dataset.play;
        const title = btn.dataset.playTitle;
        btn.addEventListener("click",async(evt)=>{
            if (!player) { 
                initializePlayer();
                player.onplaychange = handleplaychange; 
            }
            if ((player.running === true) && (player.src == src)) player.pauseclip();
            else if (player.running === true) {
                await player.stopclip();
                if (player.title == title) return;
                else {
                    await player.loadclip({src,title});
                    player.playclip();
                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: "smooth",
                    //   });
                    return;
                }
            }
            else if ((player.running !== true) && (player.src == src)) {
                player.playclip();
                return;
            }
            else {
                await player.stopclip()
                await player.loadclip({src,title});
                player.playclip();
                // window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                //   });
                return;
            }
        });
    });
    // load first track
    const firstTrack = playButtons[0];
    const src = firstTrack.dataset.play;
    const title = firstTrack.dataset.playTitle;
    await player.loadclip({src,title});


});
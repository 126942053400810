import {
    Bezier
} from 'bezier-js';
const BezierEasing = require('bezier-easing');

function lineToAngle({
    x1 = 0,
    y1 = 0,
    length = 50,
    angle = 45
}) {

    angle *= Math.PI / 180;

    var x2 = x1 + length * Math.cos(angle),
        y2 = y1 + length * Math.sin(angle);

    return {
        x: x2,
        y: y2
    };
}

function calcAngleDegrees(x = 0, y = 0) {
    return Math.atan2(y, x) * (180 / Math.PI);
}

function angleBetween(x1 = 0, y1 = 0, x2 = 100, y2 = 100) {
    return calcAngleDegrees(x2 - x1, y2 - y1);
}

function lineCenter(x1 = 0, y1 = 0, x2 = 100, y2 = 100) {
    return {
        x: (x2 - x1) / 2 + x1,
        y: (y2 - y1) / 2 + y1
    }
}

const makeSPath = function (coords = {
    x0: 0,
    y0: 0,
    x1: 100,
    y1: 100
}) {

    var p = new Path2D();
    p.moveTo(coords.x0, coords.y0);
    p.quadraticCurveTo(
        coords.x0 + ((coords.x1 - coords.x0) / 2),
        coords.y0,
        coords.x0 + ((coords.x1 - coords.x0) / 2),
        coords.y0 + ((coords.y1 - coords.y0) / 2)
    );
    p.quadraticCurveTo(
        coords.x0 + ((coords.x1 - coords.x0) / 2),
        coords.y1,
        coords.x1,
        coords.y1
    );
    return p;
};

const makeArrowPath = function (coords = {
    x0: 0,
    y0: 0,
    x1: 100,
    y1: 100
}, control, headAngle = 60, headLength = 10) {
    if (control == undefined) {
        control = {
            x: (coords.x1 - coords.x0) / 2,
            y: (coords.y1 - coords.y0) / 2
        };
    }
    let p = new Path2D();
    p.moveTo(coords.x0, coords.y0);
    p.quadraticCurveTo(
        control.x,
        control.y,
        coords.x1,
        coords.y1
    );
    let finalAngle = angleBetween(coords.x1, coords.y1, control.x, control.y);
    p.moveTo(coords.x1, coords.y1);
    let arrowA = lineToAngle(coords.x1, coords.y1, headLength, finalAngle - headAngle / 2);
    p.lineTo(arrowA.x, arrowA.y);

    p.moveTo(coords.x1, coords.y1);
    let arrowB = lineToAngle(coords.x1, coords.y1, headLength, finalAngle + headAngle / 2);
    p.lineTo(arrowB.x, arrowB.y);

    return p;

};

const ratio = window.devicePixelRatio;
const getRadians = (v) => v * Math.PI / 180;
const getDegrees = (v) => v * 180 / Math.PI;

const inter_functions = {
    linear: (t, b, c, d) => {
        return ((c - b) * t) / d + b;
    },
    easeInQuad: (t, b, c, d) => {
        t /= d;
        return (c - b) * t * t + b;
    },
    easeOutQuad: (t, b, c, d) => {
        t /= d;
        return -(c - b) * t * (t - 2) + b;
    },
    easeInOutQuad: (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return ((c - b) / 2) * t * t + b;
        t--;
        return (-(c - b) / 2) * (t * (t - 2) - 1) + b;
    }
};

const interpolate = ({
    a=0,
    b=1,
    time,
    duration,
    easing = "easeInOutQuad",
}) => {
    
    let bezierEasing = Array.isArray(easing) ? BezierEasing(...easing) : false;
    const getVal = !bezierEasing ? inter_functions[easing] : (t, b, c, d)=>{
        t /= d;
        return (bezierEasing(t) * (c - b)) + b;
    };
    if (Array.isArray(a)) {
        return a.map((value, i) => {
            return getVal(time, a[i], b[i], duration);
        });
    } else if (isFinite(a)) {
        return getVal(time, a, b, duration);
    } else {
        return a;
    }
};

function quadraticBezierLength(p0, p1, p2) {

    p0.x = p0.x == 0 ? p0.x + 0.001 : p0.x;
    p0.y = p0.y == 0 ? p0.y + 0.001 : p0.y;

    p1.x = p1.x == 0 ? p1.x + 0.001 : p1.x;
    p1.y = p1.y == 0 ? p1.y + 0.001 : p1.y;

    p2.x = p2.x == 0 ? p2.x + 0.001 : p2.x;
    p2.y = p2.y == 0 ? p2.y + 0.001 : p2.y;

    var ax = p0.x - 2 * p1.x + p2.x;
    var ay = p0.y - 2 * p1.y + p2.y;
    var bx = 2 * p1.x - 2 * p0.x;
    var by = 2 * p1.y - 2 * p0.y;
    var A = 4 * (ax * ax + ay * ay);
    var B = 4 * (ax * bx + ay * by);
    var C = bx * bx + by * by;

    var Sabc = 2 * sqrt(A + B + C);
    var A_2 = sqrt(A);
    var A_32 = 2 * A * A_2;
    var C_2 = 2 * sqrt(C);
    var BA = B / A_2;

    return (A_32 * Sabc + A_2 * B * (Sabc - C_2) + (4 * C * A - B * B) * log((2 * A_2 + BA + Sabc) / (BA + C_2))) / (4 * A_32);
};

const pens = {
    "dot" : (x,y,thickness,ctx)=>{
        ctx.beginPath();
        ctx.moveTo(x,y);
        ctx.ellipse(x,y,thickness,thickness,0,0,2*Math.PI);
        ctx.fill();
    },
    "square" : (x,y,thickness,ctx)=>{
        ctx.beginPath();
        ctx.rect(x-(thickness),y-(thickness),thickness*2,thickness*2);
        ctx.fill();
    },
    "nib" : (x,y,thickness,ctx)=>{
        ctx.beginPath();
        // ctx.rect(x-(thickness),y-(thickness),thickness*2,thickness*2);
        // ctx.fill();
        ctx.moveTo(x-thickness/2,y+thickness/2);
        ctx.lineTo(x,y+thickness/2);
        ctx.lineTo(x+thickness/2,y-thickness/2);
        ctx.lineTo(x,y-thickness/2);
        ctx.fill();
      }
};

class SinglePath {

    // for line, arcs or quad or bezier curves
    constructor() {
        this.currentPath = null;
        this.start = [100, 100];
        this.end = [200, 200];
        this.type = "line"; // arc | quad | bezier | line
        this.control0 = [140, 160];
        this.control1 = [160, 140];
        this.radius = 100;
        this.center = [100, 200];
        this.startAng = 0;
        this.endAng = 90;
        this.angle = 0;
        return this;
    }

    get path() {
        // default to line if path unset
        if (this.currentPath !== null) return this.currentPath;
        else {
            let path = new Path2D();
            path.moveTo(...this.start.map(v=>v*ratio));
            path.lineTo(...this.end.map(v=>v*ratio));
            this.currentPath = path;
            return path;
        }
    }


    get lineLength() {
        if (!["line", "arc", "quad", "bezier"].includes(this.type)) {
            console.warn("SinglePath: can't compute length of current type, sorry");
            return null;
        }
        switch (this.type) {
            case "line":
                return Math.sqrt(Math.pow((this.start[0] - this.end[0]), 2) + Math.pow((this.start[1] - this.end[1]), 2));
            case "quad":
            case "bezier":
                let curve = this.type == "quad" ? new Bezier(...this.start, ...this.control0, ...this.end) : new Bezier(...this.start, ...this.control0, ...this.control1, ...this.end);
                return curve.length();
            case "arc":
                return getRadians(this.endAng - this.startAng) * this.radius;
        }
    }

    getPoints({
        grain = 100
    } = {}) {
        if (!["line", "arc", "quad", "bezier"].includes(this.type)) {
            console.warn("SinglePath: can't compute length of current type, sorry");
            return null;
        }
        switch (this.type) {
            case "quad":
            case "bezier":
                let curve = this.type == "quad" ? new Bezier(...this.start, ...this.control0, ...this.end) : new Bezier(...this.start, ...this.control0, ...this.control1, ...this.end);
                return curve.getLUT(grain).map(point=>{
                    return [point.x,point.y]
                });
            case "arc":
                return Array.from(Array(grain + 1)).map((v, i) => {
                    return Object.values(lineToAngle({
                        x1: this.center[0],
                        y1: this.center[1],
                        length: this.radius,
                        angle: this.startAng + (this.endAng - this.startAng) * (i / grain)
                    }));
                });
            case "line":
                return Array.from(Array(grain + 1)).map((v, i) => {
                    return [
                        this.start[0] + ((this.end[0] - this.start[0]) * (i / grain)),
                        this.start[1] + ((this.end[1] - this.start[1]) * (i / grain)),
                    ]
                });
        }
    }

    draw(ctx) {
        if (!ctx || !(ctx instanceof CanvasRenderingContext2D)) return false;
        ctx.stroke(this.path);
        return this;
    }

    // drawVariablePath(ctx, {
    //     startPattern = [0, 33, 0, 33, 0, 33],
    //     endPattern = [0, 50, 50, 50, 0, 0],
    //     thinnest = 1,
    //     thickest = 2,
    //     grain = 50,
    //     easing = "easeInOutQuad",
    //     relative = true
    // } = {}) {
    //     if (startPattern.length !== endPattern.length) {
    //         console.warn("")
    //         return false;
    //     }
    //     let a = relative ? this.pathPortions(startPattern) : startPattern;
    //     let b = relative ? this.pathPortions(endPattern) : endPattern;
    //     let thickness, drawPattern, offset = 0;
    //     for (let time = 0; time < grain; time++) {
    //         thickness = interpolate({
    //             a: thinnest,
    //             b: thickest,
    //             time,
    //             duration: grain,
    //             easing: "linear"
    //         });
    //         drawPattern = interpolate({
    //             a,
    //             b,
    //             time,
    //             duration: grain,
    //             easing
    //         });
    //         console.debug(drawPattern);
    //         // safari fix
    //         if (drawPattern[0] == 0) {
    //             drawPattern.shift();
    //             offset = drawPattern.shift();
    //         } else {
    //             offset = 0;
    //         }
    //         ctx.lineWidth = thickness;
    //         ctx.lineDashOffset = offset;
    //         ctx.setLineDash(drawPattern);
    //         ctx.stroke(this.path);
    //     }
    //     return this;
    // }

    async drawVariablePath(ctx, {
        thickest=10,
        thinnest=5,
        jitter=0,
        easing="easeInOutQuad",
        inAndOut=true,
        pen="nib",
        grain,
        extent,
        slowDraw=0,
        leaky=false
    }={}) {
        pen = !pens[pen] ? (x,y,thickness,ctx)=>{
            ctx.beginPath();
            ctx.moveTo(x,y);
            ctx.ellipse(x,y,thickness,thickness,0,0,2*Math.PI);
            ctx.fill();
        } : pens[pen];
        grain = grain || Math.floor(this.lineLength);
        extent = !!extent ? extent * grain : grain;
        let points = this.getPoints({grain}),thicc,penJitter;
        const nextPoint = async function(time){
            let point = points[time];
            if (leaky && (Math.random() < 0.05)) {
                point = [point[0]+((Math.random()-0.5)*thickest*2),point[1]+((Math.random()-0.5)*thickest*2)];
            }
            if (!point) return false;
            if ((time > 0) && (Math.random()<jitter)) {
                penJitter = 0.5 + (Math.random()*jitter);
            }
            else {
                penJitter = 1 ;
            }
            if (!inAndOut) {
                thicc = interpolate({a:thinnest,b:thickest,duration:grain,time,easing});
            } 
            else {
                thicc = time <= grain / 2 ? interpolate({a:thinnest,b:thickest,duration:grain*2,time:time*2,easing}) : 
                interpolate({a:thinnest,b:thickest,duration:grain*2,time:(grain*2)-(time*2),easing})
            }
            if (slowDraw > 0) {
            await new Promise(resolve=>setTimeout(resolve,slowDraw));
                requestAnimationFrame(()=>{
                    pen(...point.map(v=>v*ratio),thicc*ratio*penJitter,ctx);
                });
            }
            else pen(...point.map(v=>v*ratio),thicc*ratio*penJitter,ctx);
            return true;
        }
        for (let time = 0; time <= extent; time++) {
            await nextPoint(time);   
        }
        return this;
    }

    line({
        start,
        end,
        center,
        angle,
        length
    } = {}) {
        this.type = "line";
        if (!!start && !!end) {
            this.start = start;
            this.end = end;
            this.currentPath = null;
            this.angle = angleBetween(...this.start, ...this.end);
            this.center = Object.values(lineCenter(...this.start, ...this.end));
        } else if (!!center || !!angle || !!length) {
            this.center = !!center ? center : this.center;
            this.angle = !!angle ? angle : this.angle;
            length = length || this.lineLength;
            // this.lineLength = !!length ? length : this.lineLength;
            this.start = Object.values(lineToAngle({
                x1: this.center[0],
                y1: this.center[1],
                length: length / 2,
                angle: this.angle
            }));
            this.end = Object.values(lineToAngle({
                x1: this.center[0],
                y1: this.center[1],
                length: length / 2,
                angle: this.angle + 180
            }));
            this.currentPath = null;
        } else {
            console.warn("SinglePath: line called without complete params");
            return false;
        }
        return this;
    }

    arc({
        center,
        radius,
        startAng,
        endAng,
        anticlockwise = false
    } = {}) {
        this.center = center || this.center;
        this.radius = isFinite(radius) ? radius : this.radius;
        this.startAng = isFinite(startAng) ? startAng : this.startAng;
        this.endAng = isFinite(endAng) ? endAng : this.endAng;
        this.type = "arc";
        let path = new Path2D();
        path.arc(...this.center.map(v=>v*ratio), this.radius*ratio, getRadians(this.startAng), getRadians(this.endAng), anticlockwise);
        this.currentPath = path;
        return this;
    }

    quad({
        start,
        end,
        control
    } = {}) {
        this.start = start || this.start;
        this.end = end || this.end;
        this.control0 = control || this.control0;
        this.type = "quad";
        let path = new Path2D();
        path.moveTo(...this.start.map(v=>v*ratio));
        path.quadraticCurveTo(...this.control0.map(v=>v*ratio), ...this.end.map(v=>v*ratio));
        this.currentPath = path;
        return this;
    }

    bezier({
        start,
        end,
        control0,
        control1
    } = {}) {
        this.start = start || this.start;
        this.end = end || this.end;
        this.control0 = control0 || this.control0;
        this.control1 = control1 || this.control1;
        this.type = "bezier";
        let path = new Path2D();
        path.moveTo(...this.start.map(v=>v*ratio));
        path.quadraticCurveTo(...this.control0.map(v=>v*ratio), ...this.control1.map(v=>v*ratio), ...this.end.map(v=>v*ratio));
        this.currentPath = path;
        return this;
    }

    pathPortions(portions) {
        if (!Array.isArray(portions)) return null;
        // takes array of numbers, and proportions between them
        let total = portions.reduce((a, c) => a + c);
        return portions.map(v => v / total * this.lineLength);
    }

}

export {
    getRadians,
    getDegrees,
    SinglePath,
    interpolate,
    lineToAngle,
    angleBetween
}